
import IMG_LOGO from '../assets/images/logo-elu.png';

export default {
  logo: {
    logo1:
      IMG_LOGO,
  },
  sound: {
    sound1:
      'https://storage.googleapis.com/laboon-img-storage/play-elu/soundtrack-bg/soundtrack_bg1.mp3',
    sound2:
      'https://storage.googleapis.com/laboon-img-storage/play-elu/soundtrack-bg/soundtrack_bg2.mp3',
  },
  modal: {
    modalBg:
      'https://storage.googleapis.com/laboon-img-storage/play-elu/gameplay/modal-drop/modaldrop--frame.webp',
  },
};
